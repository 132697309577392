import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {ApplicationConfig} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {provideRouter, withComponentInputBinding} from '@angular/router';

import {AppModule} from './app/app.module';
import {appRoutes} from './app/app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(appRoutes, withComponentInputBinding())
    ]
};

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
