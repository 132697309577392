<div class="breadcrumb" *ngIf="(breadcrumbs$ | async) as breadcrumbs">
    <ng-container *ngIf="breadcrumbs.length > 1">
        <a
            data-cy="breadcrumb-back-link"
            *ngIf="breadcrumbs[breadcrumbs.length - 2] as previous"
            (click)="back()"
            [matTooltip]="previous.translate ? (previous.label | translate) : previous.label"
            class="back-button">
            <span class="material-symbols-outlined">arrow_back</span>
        </a>
    </ng-container>
    <div class="list-container">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last;">
            <ng-template #label>
                <span *ngIf="breadcrumb.translate">{{ breadcrumb.label | translate }}</span>
                <span *ngIf="!breadcrumb.translate">{{ breadcrumb.label }}</span>
            </ng-template>
            <div *ngIf="breadcrumb.display" class="list-item">
                <ng-container *ngIf="!last">
                    <div class="item">
                        <a
                            [routerLink]="breadcrumb.url"
                            class="parentPage">
                            <ng-template [ngTemplateOutlet]="label"></ng-template>
                        </a>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
