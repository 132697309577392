import {Pipe, PipeTransform} from '@angular/core';
import {FzfResultItem} from 'fzf';

@Pipe({name: 'fzfHighlight'})
export class FzfHighlightPipe implements PipeTransform {
    transform(value: string, props: FzfResultItem<unknown>): string {
        const start = Math.max(0, props.start - 20);
        const chars = value.slice(start, start + 80).split(''); // display the interesting part
        const nodes = chars.map((char, i) => {
            if (props.positions.has(start + i)) {
                return `<span class="highlight">${char}</span>`;
            }

            return char;
        });

        return nodes.join('').trim();
    }
}

