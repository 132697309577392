import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {FeatureStore} from '@feature/data';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {UseCaseProvider} from './use-case.provider';
import {UseCaseState} from '../models/use-case-state.interface';
import {UseCase} from '../models/use-case.model';

export function initializeDataLoader(service: DataLoaderService, provider: UseCaseProvider, store: UseCaseStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class UseCaseStore extends EntityStore<UseCase, UseCaseState> implements OnDestroy {
    #created = new Subject<EntitiesChange<UseCase>>();
    get created(): Observable<EntitiesChange<UseCase>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<UseCase>>();
    get updated(): Observable<EntitiesChange<UseCase>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<UseCase>>();
    get deleted(): Observable<EntitiesChange<UseCase>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectUseCasesForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, useCases) => {
            if (application) {
                return useCases.filter(useCase => useCase.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectUseCasesForCurrentIS$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.selectAll$,
        (IS, useCases) => {
            if (IS) {
                return useCases.filter(useCase => useCase.informationSystem.id === IS.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.applicationStore.selectSelectedEntity$,
        (IS, application) => IS?.applications.find(app => app.id === application?.id)?.nextUseCaseCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    // Selectors
    readonly selectAllUseCasesByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        useCases => useCases.filter(useCase => useCase.feature?.id === feature.id)
    );
    readonly selectAllUseCasesByActor$ = (actor: {id: IdType}) => this.select(
        this.selectAll$,
        useCases => useCases.filter(useCase => useCase.actor?.id === actor.id)
    );

    public override onSuccess(change: EntitiesChange<UseCase>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<UseCase, UseCaseState> {
        return {
            storeName: 'UseCaseStore',
            initialState: {ids: [], entities: {}, currentUseCaseActions: []},
            selectId: useCase => useCase.id,
            sort: sortByCode,
        };
    }

    constructor(
        provider: UseCaseProvider,
        private applicationStore: ApplicationStore,
        private ISStore: InformationSystemStore,
        private featureStore: FeatureStore,
    ) {
        super(provider);

        // Refreshes the use cases when a feature is deleted
        this.subs.sink = this.featureStore.deleted.pipe(
            tap(() => {
                this.provider.resetCache();
                this.findAll();
            })
        ).subscribe();

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subs.unsubscribe();
    }
}
