import {computed, inject} from '@angular/core';
import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {VersionableType} from '@versionable/data';
import {basicMatch, byLengthAsc, extendedMatch, Fzf} from 'fzf';

import type {SearchItem} from '../models/search-item.model';
import {SearchAggregator} from '../services/search-aggregator.service';

export type AcceptedRequirement = Exclude<VersionableType, 'appcomponent'>;
export const allAcceptedRequirements: AcceptedRequirement[] = ['actor', 'datamodel', 'form', 'table', 'glossaryterm', 'feature', 'functionalrequirement', 'nonfunctionalrequirement', 'page', 'usecase'];
type SearchState = {
    query: string;
    algorithm: 'v1' | 'v2' | false; // if false, strict match
    limit: number;
    // extended match syntax: https://github.com/junegunn/fzf/tree/7191ebb615f5d6ebbf51d598d8ec853a65e2274d?tab=readme-ov-file#search-syntax
    match: 'basic' | 'extended';
    requirements: AcceptedRequirement[],
};

const initialState: SearchState = {
    query: '',
    algorithm: 'v2',
    limit: Infinity,
    match: 'basic',
    requirements: allAcceptedRequirements,
};

export const SearchStore = signalStore(
    withState(initialState),
    withMethods(store => ({
        setQuery(searchStr: string): void {
            patchState(store, {query: searchStr});
        },
        setAlgorithm(algorithm: 'v1' | 'v2' | false): void {
            patchState(store, {algorithm});
        },
        setLimit(limit: number): void {
            patchState(store, {limit});
        },
        setMatch(match: 'basic' | 'extended'): void {
            patchState(store, {match});
        },
        setRequirements(requirements: AcceptedRequirement[]): void {
            patchState(store, {requirements});
        }
    })),
    withComputed(({query, algorithm, limit, match, requirements}, aggregator = inject(SearchAggregator)) => {
        const results = computed(() => {
            const search = query();
            if (!search || !search.length) {
                return [];
            }

            const fzf = new Fzf<SearchItem[]>(aggregator.searchItems().filter(item => requirements().includes(item.typeClass as AcceptedRequirement)), {
                selector: item => item.searchStr,
                tiebreakers: [byLengthAsc],
                fuzzy: algorithm(),
                limit: limit(),
                match: 'basic' === match() ? basicMatch : extendedMatch,
            });

            return fzf.find(search);
        });

        return {
            results,
            resultCount: computed(() => results().length),
        };
    }),
);
