import {ActorStore} from '@actor/data';
import {inject, Injectable} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {DataModelStore} from '@data-model/data';
import {FeatureStore} from '@feature/data';
import {FormStore} from '@form/data';
import {FunctionalRequirementStore} from '@functional-requirement/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {MentionConversionService, MentionStore} from '@mention/data';
import {NonFunctionalRequirementStore} from '@non-functional-requirement/data';
import {PageStore} from '@page/data';
import {TableStore} from '@table/data';
import {UseCase, UseCaseStore} from '@use-case/data';
import {CodeFormatterService, Versionable, VersionableType} from '@versionable/data';
import {combineLatest, map} from 'rxjs';

import {SearchItem} from '../models/search-item.model';

@Injectable({providedIn: 'root'})
export class SearchAggregator {
    #mentionStore = inject(MentionStore);
    #dataModelStore = inject(DataModelStore);
    #actorStore = inject(ActorStore);
    #formStore = inject(FormStore);
    #tableStore = inject(TableStore);
    #termStore = inject(GlossaryTermStore);
    #featureStore = inject(FeatureStore);
    #funcReqStore = inject(FunctionalRequirementStore);
    #nonFuncReqStore = inject(NonFunctionalRequirementStore);
    #pageStore = inject(PageStore);
    #useCaseStore = inject(UseCaseStore);
    #mentionConversionService = inject(MentionConversionService);
    #codeFormatterService = inject(CodeFormatterService);

    #mentions = toSignal(this.#mentionStore.selectMentions$);
    #searchItems$ = combineLatest([
        this.#dataModelStore.selectDataModelsForCurrentIS$,
        this.#actorStore.selectActorsForCurrentIS$,
        this.#formStore.selectFormsForCurrentIS$,
        this.#tableStore.selectTablesForCurrentIS$,
        this.#termStore.selectTermsForCurrentIS$,
        this.#featureStore.selectFeaturesForCurrentIS$,
        this.#funcReqStore.selectFunctionalRequirementsForCurrentIS$,
        this.#nonFuncReqStore.selectNonFunctionalRequirementsForCurrentIS$,
        this.#pageStore.selectPagesForCurrentIS$,
        this.#useCaseStore.selectUseCasesForCurrentIS$,
    ]).pipe(
        takeUntilDestroyed(),
        map(([dataModels, actors, forms, tables, terms, features, funcReqs, nonFuncReqs, pages, useCases]): SearchItem[] => ([
            ...dataModels.map(entity => {
                const item = this.convertVersionableToSearchItem(entity, 'datamodel');
                let inlineAttributes = '';
                if (entity.dataModelAttributes) {
                    entity.dataModelAttributes.forEach(attr => inlineAttributes += `${attr.name} `);
                }
                if (entity.dataModelRelationships) {
                    entity.dataModelRelationships.forEach(rel => inlineAttributes += `${rel.ownerDataModelAttributeName} ${rel.relatedDataModelAttributeName} `);
                }

                return {
                    ...item,
                    searchStr: `${item.searchStr} ${inlineAttributes}`,
                };
            }),
            ...actors.map(entity => this.convertVersionableToSearchItem(entity, 'actor')),
            ...forms.map(entity => {
                const item = this.convertVersionableToSearchItem(entity, 'form');
                let inlineFields = '';
                if (entity.formFields) {
                    entity.formFields.forEach(field => inlineFields += `${field.name} `);
                }

                return {
                    ...item,
                    searchStr: `${item.searchStr} ${inlineFields}`,
                };
            }),
            ...tables.map(entity => {
                const item = this.convertVersionableToSearchItem(entity, 'table');
                let inlineColumns = '';
                if (entity.dataTableColumns) {
                    entity.dataTableColumns.forEach(col => inlineColumns += `${col.name} `);
                }

                return {
                    ...item,
                    searchStr: `${item.searchStr} ${inlineColumns}`,
                };
            }),
            ...terms.map(entity => {
                const item = this.convertVersionableToSearchItem(entity, 'glossaryterm');
                let synonyms = '', plurals = '';
                if (entity.synonyms) {
                    synonyms = entity.synonyms.map(term => term.word).join(' ');
                }
                if (entity.plurals) {
                    plurals = entity.plurals.map(term => term.word).join(' ');
                }

                return {
                    ...item,
                    searchStr: `${item.searchStr} ${synonyms} ${plurals}`,
                };
            }),
            ...features.map(entity => this.convertVersionableToSearchItem(entity, 'feature')),
            ...funcReqs.map(entity => this.convertVersionableToSearchItem(entity, 'functionalrequirement')),
            ...nonFuncReqs.map(entity => this.convertVersionableToSearchItem(entity, 'nonfunctionalrequirement')),
            ...pages.map(entity => this.convertVersionableToSearchItem(entity, 'page')),
            ...useCases.map(entity => this.convertUseCasesToSearchItem(entity)),
        ])),
    );

    searchItems = toSignal(this.#searchItems$, {initialValue: []});

    private convertVersionableToSearchItem(versionable: Versionable, type: VersionableType): SearchItem {
        const code = this.#codeFormatterService.format(versionable, type, true);

        return {
            name: versionable.name,
            id: versionable.id,
            typeClass: type,
            searchStr: `${code} ${versionable.name} ${versionable.description ? this.#mentionConversionService.replaceMentionsInString(this.#mentions() ?? [], versionable.description, true) : ''}`.replace(/<[^>]*>/g, ''),
            codeAndPrefix: code,
            sourceObject: versionable,
        };
    }

    private convertUseCasesToSearchItem(versionable: UseCase): SearchItem {
        const code = this.#codeFormatterService.format(versionable, 'usecase', true);
        const description = versionable.description ? this.#mentionConversionService.replaceMentionsInString(this.#mentions() ?? [], versionable.description, true) : '';
        const preCondition = versionable.preConditions ? this.#mentionConversionService.replaceMentionsInString(this.#mentions() ?? [], versionable.preConditions, true) : '';
        const postCondition = versionable.postConditions ? this.#mentionConversionService.replaceMentionsInString(this.#mentions() ?? [], versionable.postConditions, true) : '';
        const inlineSteps = versionable.scenarios?.map(scenario => scenario.scenarioSteps?.map(
            step => this.#mentionConversionService.replaceMentionsInString(this.#mentions() ?? [], step.description ?? '', true)
        )).join(' ') ?? '';

        return {
            name: versionable.name,
            id: versionable.id,
            typeClass: 'usecase',
            searchStr: `${code} ${versionable.name} ${description} ${preCondition} ${postCondition} ${inlineSteps}`.replace(/<[^>]*>/g, ''),
            codeAndPrefix: code,
            sourceObject: versionable,
        };
    }
}
