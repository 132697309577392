<ng-template #buttonIcon>
    <mat-icon *ngIf="icon.length" [color]="!disabled && 'icon' === buttonType ? color : ''" [matBadge]="badge" [matBadgeColor]="badgeColor" matBadgeSize="small" aria-hidden="false">
        {{ icon }}
    </mat-icon>
    <span><ng-content></ng-content></span>
</ng-template>
<ng-template #button>
    <ng-container *ngIf="!routerLink">
        <ng-container *ngIf="'normal' === buttonType">
            <button mat-button (click)="onClick()" [attr.data-cy]="cy" [disabled]="disabled"
                [color]="!disabled ? color : ''">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="'raised' === buttonType">
            <button mat-raised-button (click)="onClick()" [attr.data-cy]="cy" [disabled]="disabled"
                [color]="!disabled ? color : ''">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="'stroked' === buttonType">
            <button mat-stroked-button (click)="onClick()" [attr.data-cy]="cy" [disabled]="disabled"
                [color]="!disabled ? color : ''">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="'icon' === buttonType">
            <button mat-icon-button (click)="onClick()" [attr.data-cy]="cy" [disabled]="disabled">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="'menu-item' === buttonType">
            <button mat-menu-item (click)="onClick()" [attr.data-cy]="cy" [disabled]="disabled">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="routerLink">
        <ng-container *ngIf="'raised' === buttonType">
            <a mat-raised-button [routerLink]="routerLink" [attr.data-cy]="cy" [disabled]="disabled" *ngIf="!disabled"
                [color]="!disabled ? color : ''">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </a>
        </ng-container>
        <ng-container *ngIf="'icon' === buttonType">
            <a mat-icon-button [routerLink]="routerLink" [attr.data-cy]="cy" [disabled]="disabled" *ngIf="!disabled"
                [color]="!disabled ? color : ''">
                <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
            </a>
        </ng-container>
    </ng-container>
</ng-template>

<span
    *ngIf="tooltip;else:button"
    [matTooltip]="tooltip"
    [matTooltipClass]="tooltipClass"
    [matTooltipPosition]="tooltipPosition">
    <ng-container *ngTemplateOutlet="button"></ng-container>
</span>
