<form [formGroup]="form">
    <div class="row">
        <div class="col small">
            <mat-form-field>
                <mat-label>{{ 'COMMON.CODE' | translate }}</mat-label>
                <input type="number" matInput required formControlName="code" data-cy="datamodel-code-input">
                <span matTextPrefix>{{ prefix }}&nbsp;</span>
                <mat-error *ngIf="form.get('code')?.hasError('required')">
                    {{ 'ERROR.INPUT_NOT_COMPLETE' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('code')?.hasError('api')">
                    {{ form.get('code')?.getError('api') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'DATA_MODEL.DATA_MODEL_NAME' | translate }}</mat-label>
                <input
                    [maxlength]="formType.getOptions().nameMaxLength"
                    matInput
                    formOnlyAlphaSpaceAccentNumeric
                    required
                    formControlName="name"
                    data-cy="datamodel-name-input">
                <mat-error *ngIf="form.get('name')?.hasError('required')" data-cy="datamodel-name-error">
                    {{ 'ERROR.INPUT_NOT_COMPLETE' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('name')?.hasError('maxlength')" data-cy="datamodel-name-error">
                    {{ 'ERROR.INPUT_MAX_LENGTH' | translate: {'max':
                    form.get('name')?.getError('maxlength')?.requiredLength} }}
                </mat-error>
                <mat-error *ngIf="form.get('name')?.hasError('api')" data-cy="datamodel-name-error">
                    {{ form.get('name')?.getError('api') }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'COMMON.TYPE' | translate }}</mat-label>
                <mat-select formControlName="type" required>
                    <mat-option
                        *ngFor="let type of types"
                        [value]="type">
                        {{ 'DATA_MODEL.TYPES.' + type | uppercase | translate }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="form.get('type')?.hasError('required')"
                    data-cy="form-field-name-error">{{ 'ERROR.INPUT_NOT_COMPLETE' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('type')?.hasError('api')">
                    {{ form.get('type')?.getError('api') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col" *ngIf="'enum' !== form.get('type')?.value">
            <form-select-search
                data-cy="data-model-inherits-select"
                [data]="dataModelsInherits"
                [controlName]="'inherits'"
                [compare]="compare"
                [isNullable]="true"
                [formGroupParam]="form"
                [filterMethod]="filter">
                <ng-template #itemLabel let-data="data">
                    {{ data | code: 'datamodel' | async }} - {{ data.name }}
                </ng-template>
                <ng-container label>
                    {{ 'DATA_MODEL.DATA_MODEL_INHERITS' | translate }}
                </ng-container>
                <ng-container error>
                    <div *ngIf="form.get('inherits')?.hasError('api')">
                        {{ form.get('inherits')?.getError('api') }}
                    </div>
                </ng-container>
            </form-select-search>
        </div>
    </div>
    <div class="row" *ngIf="vm$ | async as vm">
        <div class="col" formGroupName="generateGlossaryTerm">
            <mat-checkbox
                data-cy="datamodel-generate-gt-checkbox"
                *ngIf="vm.state !== 'edition' && !disableGlossaryTerm"
                formControlName="generate">
                {{ 'DATA_MODEL.GENERATE_GLOSSARY_TERM' | translate }}
            </mat-checkbox>
            <mat-error
                *ngIf="form.hasError('glossaryTerm') && (form.get('generateGlossaryTerm')?.touched || form.get('generateGlossaryTerm')?.touched)">
                {{ 'DATA_MODEL.GENERATE_GLOSSARY_TERM_ERROR' | translate }}
            </mat-error>
        </div>
        <div class="col">
            <span *ngIf="!form.get('generateGlossaryTerm')?.get('generate')?.value">
                <form-select-search
                    *ngIf="allGlossaryTerms$ | async as useCases"
                    [data]="useCases"
                    [controlName]="'glossaryTerm'"
                    [compare]="compare"
                    [formGroupParam]="form"
                    [filterMethod]="filterName"
                    [isNullable]="true">
                    <ng-template #itemLabel let-data="data">{{ data.word }}</ng-template>
                    <ng-container label>
                        {{ 'GLOSSARY.GLOSSARY_TERM' | translate }}
                    </ng-container>
                    <ng-container error>
                        <div *ngIf="form.get('glossaryTerm')?.hasError('api')">
                            {{ form.get('glossaryTerm')?.getError('api') }}
                        </div>
                    </ng-container>
                </form-select-search>
            </span>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-label class="ckeditor-label">{{ 'COMMON.DESCRIPTION' | translate }}</mat-label>
            <ckeditor-mentions
                [currentRequirement]="dataModel"
                formControlName="description"
                data-cy="data-model-description-input">
            </ckeditor-mentions>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'DATA_MODEL.FUNCTIONAL_DOMAINS' | translate }}</mat-label>
                <mat-chip-grid #functionalDomainsChipList>
                    @for (domain of form.get('functionalDomains')?.value; track domain) {
                    <mat-chip-row
                        (removed)="removeDomain(domain)">
                        {{ domain }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    }
                    <input matInput
                        [matChipInputFor]="functionalDomainsChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addDomain($event)"
                        formControlName="functionalDomainsControl">
                </mat-chip-grid>
            </mat-form-field>
        </div>
    </div>

    <div class="row" *ngIf="isCreateMode && 'enum' !== form.get('type')?.value"
        formGroupName="generatePage">
        <div class="col">
            <mat-checkbox formControlName="generate">
                {{ 'APPLICATION.PAGE_GENERATE' | translate }}
            </mat-checkbox>
            <mat-error
                *ngIf="form.hasError('application')">
                {{ 'APPLICATION.PAGE_GENERATE_ERROR' | translate }}
            </mat-error>
        </div>
        <div class="col" *ngIf="form.get('generatePage')?.get('generate')?.value">
            <form-select-search
                *ngIf="filteredApplications$ | async as apps"
                [data]="apps"
                [controlName]="'application'"
                [compare]="compare"
                [formGroupParam]="form.get('generatePage')"
                [filterMethod]="filterName"
                [isNullable]="false">
                <ng-template #itemLabel let-data="data">{{ data.name }}</ng-template>
                <ng-container label>
                    {{ 'APPLICATION.APPLICATION' | translate }}
                </ng-container>
            </form-select-search>
        </div>
    </div>
</form>
