import {Component} from '@angular/core';

@Component({
    template: `
        <div class="error-page text-center">
            <mat-card class="content text-center">
                <span class="error">404</span>
                <mat-card-header>
                    <mat-card-subtitle>{{ '404.SUBTITLE' | translate }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>{{ '404.CONTENT_TEXT' | translate }}</p>
                    <a class="home-button" [routerLink]="['/']" mat-stroked-button color="primary">{{
                        '404.REDIRECT_TEXT' | translate
                    }}</a>
                </mat-card-content>
                <div class="image-container text-center">
                    <img class="image-logo light" alt="ReqBuilder Logo" src="../../assets/images/reqbuilder-logo.svg" />
                    <img
                        class="image-logo dark"
                        alt="ReqBuilder Logo"
                        src="../../assets/images/reqbuilder-logo-white.svg"
                    />
                </div>
            </mat-card>
        </div>
    `,
})
export class NotFoundComponent {}
