import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {IdType} from '@axiocode/entity';
import {BehaviorSubject, Observable, distinctUntilChanged, filter, map, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {PlanItem} from '../models/item.interface';

@Injectable({providedIn: 'root'})
export class NavigationPlanService implements OnDestroy {
    #subs = new SubSink();
    #entries = new BehaviorSubject<PlanItem[]>([]);
    get entries(): Observable<PlanItem[]> {
        return this.#entries.asObservable();
    }
    #currentSubject?: {id: IdType} = undefined;

    // eslint-disable-next-line no-empty-function
    constructor(private router: Router) {}

    initialize(): void {
        this.#subs.sink = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                const root = this.router.routerState.snapshot.root;

                let route = root;
                while (route.firstChild) { route = route.firstChild; }

                return route ?? root;
            }),
            filter(route => 'primary' === route.outlet),
            map(route => {
                // Dirty trick to get the URL from the private property "_routerState".
                let url = (route as unknown as {_routerState: {url: string}})._routerState.url;

                // Remove all secondary routers from the "cached" URL so that we only compare on the main outlet in distinctUntilChanged().
                url = url.replace(/\(.*\)/gm, '');

                return url;
            }),
            distinctUntilChanged(),
            tap(() => this.reset())
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    add(entry: PlanItem): void {
        if (undefined === this.#currentSubject || this.#currentSubject.id === entry.subject.id) {
            this.#currentSubject = entry.subject;
            const entries = this.upsert(entry);
            this.#entries.next(entries);
        }
    }

    remove(id: string): void {
        const entries = this.#entries.getValue();
        const index = entries.findIndex(value => value.id === id);
        if (index >= 0) {
            entries.splice(index, 1);
            this.#entries.next(entries);
        }
    }

    reset(): void {
        this.#entries.next([]);
        this.#currentSubject = undefined;
    }

    private upsert(entry: PlanItem): PlanItem[] {
        const entries = this.#entries.getValue();
        // check if the entry is already in the array
        const index = entries.findIndex(value => value.id === entry.id);
        if (index >= 0) {
            entries[index] = entry;
        } else {
            entries.push(entry);
        }

        return entries;
    }
}
