import {Injectable} from '@angular/core';
import {IdType} from '@axiocode/entity';
import {RouteBuilder} from '@axiocode/routing';

import {ActorRoutes} from './actor-routes.service';
import {ApplicationRoutes} from './application-routes.service';
import {DataModelRoutes} from './data-model-routes.service';
import {DiscussionRoutes} from './discussion-routes.service';
import {GlossaryTermRoutes} from './glossary-term-routes.service';

@Injectable({providedIn: 'any'})
export class InformationSystemRoutes extends RouteBuilder {
    details = (id: IdType) => this.urlFromCommands([id]);
    in = (id: IdType | undefined) => this.childRoutes(String(id), InformationSystemDetailsRoutes);
}

@Injectable({providedIn: 'any'})
export class InformationSystemDetailsRoutes extends RouteBuilder {
    actor = this.childRoutes('actor', ActorRoutes);
    dataModel = this.childRoutes('datamodel', DataModelRoutes);
    glossaryTerm = this.childRoutes('glossaryterm', GlossaryTermRoutes);
    features = this.childRoutes('feature', InformationSystemFeaturesRoutes);
    discussion = this.childRoutes('discussion', DiscussionRoutes);
    application = this.childRoutes('application', ApplicationRoutes);
    branch = this.childRoutes('branch', BranchRoutes);
    search = (query: string) => this.urlFromCommands(['search', query]);
}

@Injectable({providedIn: 'any'})
export class InformationSystemFeaturesRoutes extends RouteBuilder {
    list = () => this.url('list');
}

@Injectable({providedIn: 'any'})
export class BranchRoutes extends RouteBuilder {
    merge = (id: IdType) => this.urlFromCommands(['merge', id]);
    history = () => this.url('history');
}
