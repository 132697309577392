<mat-drawer-container class="main-layout-container" autosize>
    <div class="main-container">
        <div class="nav-button"></div>
        <div align="center" class="container-login">
            <mat-card appearance="outlined" class="text-center login">
                <img
                    mat-card-image
                    class="light"
                    alt="ReqBuilder Logo"
                    src="/assets/auth/images/reqbuilder-logo.svg"
                    width="400"/>
                <img
                    mat-card-image
                    class="dark"
                    alt="ReqBuilder Logo"
                    src="/assets/auth/images/reqbuilder-logo-white.svg"
                    width="400"/>
                <mat-card-title>
                    {{ 'LOGIN.LOGIN_TITLE' | translate }}
                </mat-card-title>
                <mat-card-content>

                    <auth-login-form
                        (submitted)="authenticate($event)"
                        [errorMessage]="(error$ | async) ?? undefined"
                        [loading]="(requesting$ | async) ?? false"></auth-login-form>
                    <auth-google-button class="flex-center mt-1"
                        *ngIf="config.enableGoogleAuth"
                    ></auth-google-button>

                    <div>
                        <a
                            [routerLink]="routes.auth.passwordForgotten().url"
                            color="primary"
                            data-cy="to-forgotten-password"
                            mat-button>
                            {{ 'LOGIN.FORGOTTEN_PASSWORD' | translate }}
                        </a>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-drawer-container>

