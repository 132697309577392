<mat-toolbar *ngIf="vm$ | async as vm">
    <mat-toolbar-row>
        <div class="application-switcher">
            @if (vm.isLoggedIn && vm.currentUser) {
            <toolbar-organization-switcher [currentOrganization]="vm.currentOrganization" />
            }
        </div>

        <div class="application-actions">
            <!-- SEARCH BAR -->
            @if (vm.isLoggedIn && vm.currentIS) {
            <button *ngIf="vm.isLoggedIn && vm.currentIS"
                (click)="openInputSearch()"
                [hotkey]="hotkeys.SEARCH"
                hotkeyGroup="KEYMAP.GROUP.GENERAL"
                hotkeyDescription="KEYMAP.SEARCH_OPEN"
                mat-icon-button
                data-cy="toolbar-search-trigger">
                <span class="material-symbols-outlined">search</span>
            </button>
            <toolbar-global-search
                [hidden]="!vm.isSearchOpened"
                [application]="vm.currentApplication">
            </toolbar-global-search>
            }

            <!-- QUICK MENU -->
            <button *ngIf="vm.isLoggedIn"
                [matMenuTriggerFor]="contextualMenu"
                mat-icon-button
                data-cy="toolbar-menu-trigger">
                <span class="material-symbols-outlined">apps</span>
            </button>
            <mat-menu #contextualMenu="matMenu" class="contextual-menu">
                <a data-cy="toolbar-menu-dropdown-application-selection" mat-menu-item (click)="goTo({destination: 'application_selection'})">
                    <span class="material-symbols-outlined">apps</span>
                    <span>{{ 'APPLICATION.APPLICATION_SELECTION' | translate }}</span>
                </a>
                <a data-cy="toolbar-menu-dropdown-organization-list" mat-menu-item (click)="goTo({destination: 'organizations'})">
                    <span class="material-symbols-outlined">groups</span>
                    <span>{{ 'ORGANIZATION.ORGANIZATION_LIST' | translate }}</span>
                </a>
                <div *ngIf="vm.currentIS && vm.currentApplication">
                    <a mat-menu-item
                        (click)="goTo({destination: 'application_details', informationSystem: vm.currentIS, application: vm.currentApplication})">
                        <span class="material-symbols-outlined">visibility</span>
                        <span>{{ 'APPLICATION.APPLICATION_DETAIL' | translate }}</span>
                    </a>
                </div>
                <mat-divider></mat-divider>
                <ng-container *ngIf="vm.currentIS">
                    <button
                        *isGranted="{target: vm.currentIS, permission: 'write', type: 'is'}"
                        [matMenuTriggerFor]="informationSystemMenu"
                        mat-menu-item>
                        {{ 'INFORMATION_SYSTEM.INFORMATION_SYSTEM' | translate }}
                    </button>
                </ng-container>
                <ng-container *ngIf="vm.currentIS && vm.currentApplication">
                    <button
                        *isGranted="{target: vm.currentIS, permission: 'write', type: 'is'}"
                        [matMenuTriggerFor]="applicationMenu"
                        mat-menu-item>
                        {{ 'APPLICATION.APPLICATION' | translate }}
                    </button>
                </ng-container>
            </mat-menu>
            <mat-menu #informationSystemMenu="matMenu">
                <div *ngIf="vm.currentIS">
                    <ng-container *ngFor="let item of vm.ISMenuItems">
                        <toolbar-quick-menu-item
                            *isGranted="{target: vm.currentIS, permission: 'write', type: 'is'}"
                            (click)="openDialogAdd(item, vm.currentApplication, vm.currentIS)"
                            [item]="item"
                            [displayShortcut]="vm.discoveryMode">
                        </toolbar-quick-menu-item>
                    </ng-container>
                </div>
            </mat-menu>
            <mat-menu #applicationMenu="matMenu">
                <div *ngIf="vm.currentApplication && vm.currentIS">
                    <ng-container *ngFor="let item of vm.applicationMenuItems">
                        <toolbar-quick-menu-item
                            *isGranted="{target: vm.currentIS, permission: 'write', type: 'is'}"
                            (click)="openDialogAdd(item, vm.currentApplication, vm.currentIS)"
                            [item]="item"
                            [displayShortcut]="vm.discoveryMode">
                        </toolbar-quick-menu-item>
                    </ng-container>
                </div>
            </mat-menu>
            <!-- DISCUSSIONS -->
            @if(vm.currentIS) {
            <div *isGranted="{target: vm.currentIS, permission: 'comment', type: 'is'}">
                <button
                    *ngIf="vm.allOpenedDiscussions !== 0"
                    (click)="goTo({destination: 'discussion', application: vm.currentApplication, informationSystem: vm.currentIS})"
                    mat-icon-button>
                    <span
                        class="material-symbols-outlined"
                        [matBadge]="vm.allOpenedDiscussions"
                        matBadgePosition="above"
                        matBadgeColor="warn"
                        matBadgeSize="small"
                        aria-hidden="false"
                        data-cy="toolbar-menu-trigger">
                        forum
                    </span>
                </button>
                <button
                    *ngIf="vm.allOpenedDiscussions === 0"
                    (click)="goTo({destination: 'discussion', application: vm.currentApplication, informationSystem: vm.currentIS})"
                    mat-icon-button>
                    <span
                        class="material-symbols-outlined"
                        data-cy="toolbar-menu-trigger">
                        forum
                    </span>
                </button>
            </div>
            }
            <!-- USER MENU -->
            <button [matMenuTriggerFor]="menu" mat-icon-button>
                <span class="material-symbols-outlined">account_circle</span>
            </button>
            <mat-menu #menu="matMenu" class="user-menu">
                <button
                    *ngIf="vm.isLoggedIn && vm.currentUser"
                    (click)="goTo({destination: 'user_details', userId: vm.currentUser.id})"
                    mat-menu-item>
                    {{ vm.currentUser | displayName }}
                </button>
                <toolbar-lang-switcher></toolbar-lang-switcher>
                <div
                    (click)="$event.stopPropagation(); toggleDiscoveryMode()"
                    [attr.aria-checked]="vm.discoveryMode"
                    mat-menu-item
                    role="menuitemcheckbox">
                    <mat-slide-toggle
                        [aria-label]="'DISCOVERY.ENABLE' | translate"
                        [checked]="vm.discoveryMode">
                    </mat-slide-toggle>
                    {{ 'DISCOVERY.ENABLE' | translate }}
                </div>
                <button mat-menu-item *ngIf="vm.isLoggedIn" class="logout" (click)="logout()">
                    {{ 'LOGIN.LOGOUT' | translate }}
                </button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
